<template>
  <v-container>
    <h3>Progress</h3>
    <v-row class="mt-5">
      <v-col>
        <v-card  elevation="5" width="230px" class="rounded-lg">
          <div class="card-light ma-0" :class="{ 'card-dark': theme }">
            <v-card-text flat class="">
              <div class="white--text text-h3 mt-5 font-weight-bold">
                <span>{{ enrolledChallengesCount }}</span>
              </div>

              <div class="mt-15">
                <div>Challenges</div>
                <span>Enrolled</span>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="230px" class="rounded-lg">
          <div class="card-light" :class="{ 'card-dark': theme }">
            <v-spacer></v-spacer>
            <v-card-text flat class="">
              <div class="white--text text-h3 mt-5 font-weight-bold">
                <span>{{ enrolledProblemsCount }}</span>
              </div>

              <div class="mt-15">
                <div>Problems</div>
                <span>Attempted</span>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      
      <v-col>
        <v-card width="230px" class="rounded-lg">
          <div class="card-light" :class="{ 'card-dark': theme }">
            <v-spacer></v-spacer>
            <v-card-text flat class="">
              <div class="white--text text-h3 mt-5 font-weight-bold">
                <span>{{ getEigenTokens }}</span>
              </div>

              <div class="mt-15">
                <div>Eigen </div>
                <span>Tokens</span>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="230px" class="rounded-lg">
          <div class="card-light" :class="{ 'card-dark': theme }">
            <v-spacer></v-spacer>
            <v-card-text flat class="">
              <div class="white--text text-h3 mt-5 font-weight-bold">
                <span>{{ badges.length }}</span>
              </div>
              <div class="mt-15">
                <div>Badges </div>
                <span>Earned</span>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <h3 class="mt-5"> Recent Challenges</h3>
    <div v-if="challenges.length > 0" class="mt-5"> 
      <splide :options="options">
        <splide-slide v-for="(challenge, index) in challenges" :key="index">
          <v-hover
            v-slot="{ hover }"
          >
            <v-card
            width="230px"
            min-height="210px"
            class="text-center rounded-lg" 
            @click="$router.push(`/challenge/overview/${challenge.node.pk}`)"
            :elevation="hover ? 16 : 2"
            >
              <div class="card-light" :class="{ 'card-dark': theme }">
                <v-card-title class="white--text">
                  <v-spacer></v-spacer>
                  <span class="d-inline-block text-truncate">{{ challenge.node.title }}</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-progress-circular
                      :rotate="100"
                      :size="90"
                      :value="percentage(challenge)"
                      color="primary"
                    >
                      <span> {{ percentage(challenge) }}%</span>
                    </v-progress-circular>
                  </div>

                  <div class="mt-3">
                    <div> {{ `${challenge.node.solvedProblems.edgeCount?challenge.node.solvedProblems.edgeCount:0}/ ${problemCount(challenge)}` }} </div>
                    <span>Problems</span>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </v-hover>
        </splide-slide>
      </splide>
      
    </div>
    <div class="text-center mt-5" v-else>
      <hr class=" mt-5">
      <div class="py-5">
        <span> You have no Challenges enrolled yet</span>
      </div>
      <hr>
    </div>

    <h3 class="mt-5">Badges</h3>
    <div v-if="badges.length > 0">
      <div class="mt-5" v-for="(badge, index) in badges" :key="index">
        <BadgeComponent :badge="badge" />
      </div>
    </div>
    <div class="text-center mt-5" v-else>
      <hr class=" mt-5">
      <div class="py-5">
        <span> You have no badges yet</span>
      </div>
      <hr>
    </div>
    
  </v-container>
</template>
<script>
import colorMxn from '@/mixins/color_mixin';
import { DASHBOARD_QUERY } from '@/queries/dashboard';
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    BadgeComponent: () => import('./components/badgeComponent')
  },
  mixins: [colorMxn],
  data() {
    return {
      options: {
        rewind:true,
        perPage: 4,
        gap: '1rem',
        trimSpace: false,
			},
      loading: false,
      badges: [],
      challenges: [],
      enrolledProblemsCount: 0,
      enrolledChallengesCount: 0,
      tokens: 0,
      badgesCount: 0,
    }
  },
  computed: {
    ...mapGetters(['getEigenTokens']),
  },
  watch: {
    challenges(val) {
      if (val.length === 1) {
        this.options.perPage = 1;
      } else {
        this.options.perPage = 4;
      }
    },
  },
  mounted() {
    this.$store.commit('getUser');
    this.fetchDashboardData();
  },
  methods: {
    problemCount(challenge) {
      let result = 0;

      challenge.node.chapterSet.edges.forEach(element => {
          result += element.node.problemSet.edgeCount;
      });
      return result;
    },
    percentage(challenge) {
      const result = (challenge.node.solvedProblems.edgeCount?challenge.node.solvedProblems.edgeCount:0)/ this.problemCount(challenge) * 100
      return Math.round(result);
    },
    fetchDashboardData() {
      this.loading = true;
      this.$apollo.query({
        query: DASHBOARD_QUERY,
        variables: {
          "challengesFirst": 2,
        },
      }).then(response => {
          const data = response.data;
          this.enrolledChallengesCount = data.enrolledChallenges.totalCount;
          this.enrolledProblemsCount = data.enrolledProblems.totalCount;
          this.badges = data.myBadges.edges;
          this.badgesCount = data.myBadges.totalCount;
          this.challenges = data.enrolledChallenges.edges; 
          this.loading = false;
      });

    }
  }
}
</script>

<style>
.card-light {
  background: linear-gradient(221.75deg, #1748CF 0%, #FFFFFF 100%);
}

.card-dark { 
  background: linear-gradient(221.75deg, rgba(10, 9, 9, 0.49) 68.23%, rgba(113, 183, 249, 0.47) 100%);
}

.badge-light {
 background: linear-gradient(221.75deg, rgba(255, 255, 255, 0.4) 44.27%, rgba(23, 72, 207, 0.4) 100%);
}

.badge-dark {
  background: linear-gradient(221.75deg, rgba(10, 9, 9, 0.196) 68.23%, rgba(113, 183, 249, 0.188) 100%);
  color: white;
}
</style>